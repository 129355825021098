
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { NotificationClient } from '@/services/Services';

@Options({})
export default class sendPushModal extends Vue {

    @Prop() users: string[]
    @Prop() route: string;

    vm: OM.SendNotificationRequestVm = new OM.SendNotificationRequestVm();

    created(){
        // this.users.splice(0);
        // this.users.push('4a05ce13-b716-4de9-b10a-26c30ad992b2');

        this.vm.userIdentifiers = this.users;
        this.vm.route = this.route;
    }

    send(){
        NotificationClient.sendPush(this.vm)
        .then(x => {
            this.$emit('close');
        })
    }

}
