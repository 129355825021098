import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/open-survey-list',
            name: 'open-survey-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/open-survey-list',
                    name: 'open-survey-list',
                    component: () => import("./views/domandeAperteList.vue")
                },
                {
                    path: '/open-survey/:identifier?',
                    name: 'open-survey',
                    component: () => import("./views/survey.vue")
                },
                {
                    path: '/open-survey/:identifier/answers',
                    name: 'open-survey-answers',
                    component: () => import("./views/answersToSurvey.vue")
                },
            ]
        },
       
    ];
};