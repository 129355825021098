import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import modalPlugin from './plugins/modalPlugin';
import OpTable from '@/components/opTable/opTable.vue';
import FTS from '@/filters';
import * as DIR from '@/directives';

import '@/assets/css/font.css';
import '@/assets/css/custom_table.css';
import '@/assets/css/op-table.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.css';

import { LocalizationServices } from './services/LocalizationServices';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective);
    app.config.globalProperties.$filters = FTS;
    app.config.globalProperties.$localizationService = LocalizationServices;

//questo aggiunge automaticamente ogni componente nella cartella 'components'
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

app.mount('#app');
