import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/domandeList',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '',
                    name: 'domandeList',
                    component: () => import("./views/domandeList.vue")
                },
            ]
        },
       
    ];
};