export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}


export class ProfileResultType
{
    public static Giallo : string = "Giallo";
    public static Verde : string = "Verde";
    public static Blu : string = "Blu";
    public static Rosso : string = "Rosso";
}


export class UploadFileVm
{
    identifier: string;
    file: File;
}
export class UploadAnswerFileVm extends UploadFileVm
{
    surveyConfigIdentifier: string;
    questionIndex: number;
    answerIndex: number;
}
export class UploadCustomFilterAnswerFileVm extends UploadFileVm
{
    surveyConfigIdentifier: string;
    questionIdentifier: string;
}

export class VideoChat {
    username: string;
    link: string;
    constructor(){
        this.username = "";
        this.link = "";
    }
}