import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/events-list',
            name: 'events-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/events-list',
                    name: 'events-list',
                    component: () => import("./views/eventsList.vue")
                },
                {
                    path: '/events/:identifier?',
                    name: 'events',
                    component: () => import("./views/events.vue")
                },
                {
                    path: '/eventStats/:identifier',
                    name: 'eventStats',
                    component: () => import("./views/eventStats.vue")
                },
            ]
        },
       
    ];
};