import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/optinMeet',
            name: 'optinMeet',
            component: () => import("./views/optinMeet.vue")
        }
    ];
};