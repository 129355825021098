import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/chatnode-list',
            name: 'chatnode list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/chatnode-list',
                    name: 'chatnode list',
                    component: () => import("./views/chatNodeList.vue")
                },
                {
                    path: '/chatnode/:identifier',
                    name: 'chatNodeDetail',
                    component: () => import("./views/chatNodeDetail.vue")
                },
            ]
        },
    ];
};