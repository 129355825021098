import { createStore } from 'vuex'
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';

let store = {
    state: {
        showSpinner: 0,
        loggedUser: new OM.InternalUserVM(),
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[],
            enabledLanguages: <string[]>[],
            extendedCountries: <OM.ExtendedCountry[]>[],
            ownGenderList: <OM.TextIdentifier[]>[],
            genderList: <OM.TextIdentifier[]>[],
            profileType: <OM.TextIdentifier[]>[],
        },
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        selectedLanguage: "",
        beforeNavCbs: [],
        afterNavCbs: []
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

export default createStore(store);