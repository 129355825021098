import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';

class _CommonServices {

    showSpinner(){
        store.state.showSpinner++;
    };
    hideSpinner(){
        store.state.showSpinner--;
    };

    unauthorizedCb = () => {};
    
    private axios: AxiosInstance;
    defaultGet<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        url = replaceUrl(url, params);

        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.get<T>(url, req)
            .then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        console.log()
        return prom;
    }
    defaultPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }

    uploadFileToUrl<T = any>(url: string, file: File, params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        if (params) {
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        data.append('file', file);
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            return this.axios.post<T>(url, data, config).then(x => {
                this.hideSpinner();
                resolve(x.data);
            })
            .catch( err => {
                this.hideSpinner();
                reject(err);
            });
        });
        return prom;
    }

    setAuthToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('authtoken', token);
    }
    destroyToken() {
        this.axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('authtoken');
    }

    constructor() {
        this.axios = Axios;
        let token = window.localStorage.getItem('authtoken');
        this.axios.interceptors.response.use(function (response: any) {
            return response;
        }, function (error) {
            // if (error.response.status == 401) {
            //     this.unauthorizedCb();
            // } else {
            //     return Promise.reject(error);
            // }
            console.log(error.Message);
            if(error.response.state == 400) {
                alert("error 400");
            }
            if (error.response.status == 401) {
                if(router.currentRoute.value.path != '/')
                router.push('/?redirect=' + router.currentRoute.value.fullPath);
            } else {
                if(error.response.data.Level == "warning") {
                    // console.log("Warning: " + error.response.data.Message);
                }
                else if(error.response.data.Level == "validation") {
                    var data = error.response.data.Message;
                    // ToastedServices.Validation(data);
                    // console.log("Validation: " + data);
                }
                else if(error.response.data.Level == "error") {
                    // ToastedServices.Error(error.response.data.Message);
                    // console.log("Error in common: " + error.response.data.Exception);
                }
                if(error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
        if (token){
            this.setAuthToken(token);
        }
    }
}

function replaceUrl(url: string, params?: any): string {

    if(!params || url.indexOf("{") == -1)
        return url;

    var beginIndex = url.indexOf("{");
    var endIndex = url.indexOf("}");

    var property = url.substring(beginIndex + 1, endIndex);
    var value = params[property];

    console.log(value)

    url = url.substring(0, beginIndex) + value + url.substr(endIndex + 1);
    delete params[property];

    return replaceUrl(url, params);
}

export let CommonServices = new _CommonServices();