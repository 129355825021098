import moment from 'moment';
import { LocalizationServices } from './services/LocalizationServices';
import { StorageServices } from './services/StorageServices';
import { getUniversalDate } from './utility';

export default {

    date(value: any, format: string = "DD MMMM YYYY HH:mm:ss") {
        if(!value)
            return "-";

        // value = getUniversalDate(value);
        return moment(value).locale("it-IT").format(format);
    },

    eta(value: any) {
        if(!value)
            return "-";

        // value = getUniversalDate(value);
        return moment(new Date()).diff(moment(value), 'years').toString();
    },

    time(value: any, _format: string, _showTime: boolean) {
        if (value) {
            let format;
            if(!_format){
                format = 'HH:mm';
            } else {
                format = _format;
            }

            return moment(value).locale("it-IT").format(format);
        }
    },

    kmFromMeters(value: number, addPlus: boolean) {
        var preferredCulture = "it-IT";
        var km = value / 1000;

        var options = {
            minimumFractionDigits: 0, 
            maximumFractionDigits: 2, 
        }
        
        var format = Intl.NumberFormat(preferredCulture, options);
        var kmText = LocalizationServices.getLocalizedValue("app_Km", "Km");
        var kmValue = format.format(km);
        var middleValue = addPlus ? "+ " : " ";
        var result = kmValue + middleValue + kmText;

        return result;
    },

    chatDate(value: any) {
        if(!value)
            return;

        var date = new Date(value);
        var today = new Date();
        
        if(date.getDate() == today.getDate() - 1 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear())
            return LocalizationServices.getLocalizedValue("app_ieri", "Ieri");

        var format = "DD/MM/yyyy";
        
        //Se è stato inviato oggi
        if(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear())
            format = "HH:mm";
        //se è stato inviato questa settimana
        else if(date.getDate() >= today.getDate() - 6 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear())
            format = "dddd";

        var preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        return moment(date).locale(preferredCulture).format(format);
    },

}