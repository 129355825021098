
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Cloud extends Vue {

    @Prop() senderName: string;
    @Prop() text: string;
    @Prop({
        default: 'right'
    }) position: 'right' | 'left';
    @Prop() time: string;

    created() {
    }

}
