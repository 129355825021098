
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import store from '@/store';

@Options({})
export default class domandaRompighiaccio extends Vue {

    @Prop() participants: OM.ChatSender[];
    @Prop() readonly: boolean;
    @Prop() message: OM.ChatMessage;
    @Prop() otherName: string;
    @Prop({
        default: 'right'
    }) position: 'right' | 'left';
    @Prop() time: string;

    isSelf: boolean = false;

    created() {
        this.isSelf = store.state.loggedUser.identifier == this.message.senderIdentifier;
    }
    
    getSender(senderIdentifier: string){
        return this.participants.find(x => x.appUserIdentifier == senderIdentifier).profileName;
    }

    get yourAnswer(){
        return this.message.answers.find(x => x.senderIdentifier == store.state.loggedUser.identifier);
    }

    get canSeeOtherAnswer(){
        return true;
    }

}
