import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal flex_centered column page_padding" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "flex_centered mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.img)
        ? (_openBlock(), _createBlock("img", {
            key: 0,
            class: "mb-3",
            src: './img/' + _ctx.img
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createVNode("h2", {
        class: "fw_700 text-center mb-3",
        innerHTML: _ctx.text
      }, null, 8, ["innerHTML"]),
      _createVNode("span", {
        class: "fev_text4 text-center",
        innerHTML: _ctx.subtitle
      }, null, 8, ["innerHTML"])
    ]),
    _createVNode("div", _hoisted_3, [
      (_ctx.confirmCb)
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            class: ["ung_button", _ctx.denyCb && _ctx.confirmCb ? 'w-50' : 'w-100'],
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmCb && _ctx.confirmCb(...args)))
          }, [
            _createVNode("span", { innerHTML: _ctx.confirm }, null, 8, ["innerHTML"])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.denyCb)
        ? (_openBlock(), _createBlock("button", {
            key: 1,
            class: ["ung_button violet", _ctx.denyCb && _ctx.confirmCb ? 'w-50' : 'w-100'],
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.denyCb && _ctx.denyCb(...args)))
          }, [
            _createVNode("span", { innerHTML: _ctx.deny }, null, 8, ["innerHTML"])
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}