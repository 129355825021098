import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/chat',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/chat/:identifier',
                    name: 'chatDetail',
                    component: () => import("./views/chatDetail.vue")
                },
            ]
        },
       
    ];
};