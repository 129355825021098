import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/utentiList',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/utenti-list/:eventIdentifier?',
                    name: 'utentiList',
                    component: () => import("./views/utentiList.vue")
                },
                {
                    path: '/utenti-list/dettaglio/:userIdentifier',
                    name: 'dettaglio utente',
                    component: () => import("./views/utenteDetail.vue")
                },
                {
                    path: '/utenti-list/dettaglio/lista-match/:userIdentifier',
                    name: 'lista match utente',
                    component: () => import("./views/userMatchesList.vue")
                },
            ]
        },
       
    ];
};