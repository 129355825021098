import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/match-list/',
            name: 'matchList',
            component: () => import("./views/matchesList.vue")
        },  
        {
            path: '/match-list/chat-detail/:chatRoomIdentifier',
            name: 'chatDetail',
            component: () => import("./views/chatDetail.vue")
        },          
    ];
};