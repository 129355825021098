
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Cloud extends Vue {

    @Prop() text: string;

    @Prop({
        default: 'right'
    }) position: 'top' | 'right' | 'bottom' | 'left';

    created() {
    }

}
