import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/experts-list',
            name: 'experts-list',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/experts-list',
                    name: 'experts-list',
                    component: () => import("./views/expertsList.vue")
                },
                {
                    path: '/expert/:identifier?',
                    name: 'expert detail',
                    component: () => import("./views/expertDetail.vue")
                },
            ]
        },
       
    ];
};