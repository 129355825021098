import { CommonServices } from './CommonServices';
import * as OM from '@/Model';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    getLoggedUser() {
        var loggedUser = this.readFromLocalStorage<OM.InternalUserVM>('loggedUser');

        if(!loggedUser){
            loggedUser = new OM.InternalUserVM();
            loggedUser.preferredCulture = "it-IT";
            StorageServices.setLoggedUser(loggedUser);
        }

        return loggedUser;
    }
    
    setLoggedUser(response: OM.InternalUserVM) {
        window.localStorage.setItem('loggedUser', JSON.stringify(response));
    }

    setLoginToken(token: string) {
        return CommonServices.setAuthToken(token);
    }
    getLoginToken(): string {
        return window.localStorage.getItem('authtoken');
    }

    
    
    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
}

export let StorageServices = new _StorageServices();