
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { AppUserClient, NotificationClient } from '@/services/Services';

@Options({})
export default class userModal extends Vue {

    @Prop() users: string[]

    items: OM.UserDetailsVm[] = [];

    created(){
        AppUserClient.getUserDetailsByGuid({
            list: this.users
        })
        .then(x => {
            this.items = x;
        })
    }

}
