import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'begin',
            component: () => import("./views/login.vue")
        },
        {
            path: '/change-password/:resetPasswordToken',
            name: 'change-password',
            component: () => import("./views/change-password.vue")
        },
        {
            path: '/delete-account',
            name: 'delete account',
            component: () => import("./views/deleteAccount.vue")
        }
        
    ];
};